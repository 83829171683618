<template>
  <keep-alive  :exclude="keepAliveListExcludeNames" >
    <router-view :key='$route.fullPath' />
  </keep-alive>
</template>

<script>
  export default {
    name: 'RouteView',
    props: {
      keepAlive: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {}
    },
    computed:{
      keepAliveListExcludeNames(){

        let kpen = this.$store.state.app.keepAliveListExcludeNames
        //痛点③：怎么剔除掉不在左边菜单里面的路由页，不让keep-alive缓存？
        console.log('获取缓存的keepAliveListExcludeNames列表',kpen)
        return kpen;
      }
    }
    // render () {
    //   const { $route: { meta }, $store: { getters } } = this
    //   const inKeep = (
    //     <keep-alive>
    //       <router-view />
    //     </keep-alive>
    //   )
    //   const notKeep = (
    //       <router-view />
    //   )
    //   // 这里增加了 multiTab 的判断，当开启了 multiTab 时
    //   // 应当全部组件皆缓存，否则会导致切换页面后页面还原成原始状态
    //   // 若确实不需要，可改为 return meta.keepAlive ? inKeep : notKeep
    //   if (!getters.multiTab && !meta.keepAlive) {
    //     return notKeep
    //   }
    //   return this.keepAlive || getters.multiTab || meta.keepAlive ? inKeep : notKeep
    // }
  }
</script>
