// with polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import i18n from './locales'
import { VueAxios } from './utils/request'
import ProLayout, { PageHeaderWrapper } from '@ant-design-vue/pro-layout'
import themePluginConfig from '../config/themePluginConfig'

// mock
// WARNING: `mockjs` NOT SUPPORT `IE` PLEASE DO NOT USE IN `production` ENV.
// import './mock'

import bootstrap from './core/bootstrap'
import './core/lazy_use' // use lazy load components
import './permission' // permission control
import './utils/filter' // global filter
import './global.less' // global style
import moment from 'moment'
import FooterToolBar from '@/components/FooterToolbar';
import warehouse from './api/warehouse'
import {Tree} from 'ant-design-vue'

Vue.use(FooterToolBar)
Vue.use(Tree)
Vue.config.productionTip = false

// mount axios to `Vue.$http` and `this.$http`
Vue.use(VueAxios)
Vue.prototype.warehouse = warehouse;


// use pro-layout components
Vue.component('pro-layout', ProLayout)
Vue.component('page-container', PageHeaderWrapper)
Vue.component('page-header-wrapper', PageHeaderWrapper)

window.umi_plugin_ant_themeVar = themePluginConfig.theme


// 时间过滤器
Vue.filter('dateFormat', function(value) {
  if (value) {
    return moment(parseInt(value)).format('YYYY-MM-DD HH:mm:ss');
  }
});

Vue.filter('date', function(value) {
  if (value) {
    return moment(parseInt(value)).format('YYYY-MM-DD');
  }
});

// 公共方法
Vue.prototype.$getReqUrlByCode = function(value) {
  return this.$store.state.user.functions.get(value)
};

// 将时间字符串转换成时间戳
Vue.prototype.$dataStrToMillSecond = function(value){
  var timeStr = value;
  var timestamp = Date.parse(timeStr);
  return timestamp;
}


// 将时间字符串转换成时间戳
Vue.prototype.$dataStampToMoment= function(value){
  if (value) {
    return moment(parseInt(value)).format('YYYY-MM-DD');
  }
}

//手机号的检查
Vue.prototype.$phoneCheck = function(rule, value, callbackFn) {
  // const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/  // 手机号正则
  // 电话号码正则表达式（支持手机号码，3-4位区号，7-8位直播号码，1－4位分机号）
  const reg =
    /^(\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$/;
  if (!reg.test(value)) {
    callbackFn('手机号码格式不正确')
    return
  }
  callbackFn()
};

// 身份证的检查
Vue.prototype.$idcordCheck = function(rule,value,callback){
  // 如果为空值，就抛出错误
  if(!value){
    callback();
  }else{
    // 正则判断手机号格式的格式，正则判断失败抛出错误，否则直接callback()
    if(!/(^\d{18}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)){
      callback(new Error("身份证格式不正确!"));
    }else{
      callback();
    }
  }
},

// 邮箱的验证
  Vue.prototype.$emailCheck = function(rule, value, callbackFn){
    if (typeof (value) == "undefined" || value == "" || value.lengh == 0) {
      callback(new Error("请输入邮箱号"));
    } else if (!this.isemail(value)) {
      callback(new Error("请输入正确邮箱号"))
    } else {
      callback();
    }
  };


// 密码的验证
Vue.prototype.$passwordCheck = function(rule,value,callback){
  if(typeof(value)=="undefined" || value == "" || value.lengh==0){
    callback(new Error("请输入密码"));
  }else if(!this.isPassword(value)){
    callback(new Error("密码必须由数字、字母、特殊符号组合，请输入6-16位"))
  }else{
    callback();
  }
}

// 获取组价名
function get_Component_name(matchedList, url) {
  if(matchedList.length > 0 ){
    let index = matchedList?.findIndex(item => item.path == url)
    if(index > -1){
      return matchedList[index].components.default.name
    }
    return "";
  }
}



window.current_component_globle_remove = false
window.current_component_globle_remove_list = []
window.current_component_globle_remove_step_2_2 = null
window.current_component_globle_level_params_route = null

Vue.mixin({
  //keep-alive事件
  activated(){
    //痛点③：怎么剔除掉不在左边菜单里面的路由页，不让keep-alive缓存？
    //路由页面需要参数：说明是非左边菜单路由页面，如：详情页
    if(this.$route && this.$route.query && JSON.stringify(this.$route.query) !="{}"
      && window.current_component_globle_level_params_route == this.$route.path
      && this.$vnode
    ){
      let on = get_Component_name(this.$route.matched,this.$route.path)
      let tempKp = this.$store.state.app.keepAliveListExcludeNames
      if(tempKp && tempKp.length > 0){
        tempKp = tempKp.split(",")
        let kpf = tempKp.find((item)=>{
          return item == on
        })
        //还没有加入keepAliveListExcludeNames
        if(!kpf){
          tempKp.push(on)
          this.$store.commit('setKeepAliveListExcludeNames',tempKp.join())
        }
      }else{
        this.$store.commit('setKeepAliveListExcludeNames',[on].join())
      }
    }
    // 痛点②：关闭非当前页怎么删除它们在keep-alive的缓存？
    if (!window.current_component_globle_remove){

      let findName_ = window.current_component_globle_remove_list.find((item)=>{
        return item == this.$route.path
      })
      if(findName_){
        if (this.$vnode && this.$vnode.data.keepAlive){
          // 重置不是当前页面关闭
          window.current_component_globle_remove = false
          window.current_component_globle_remove_list =
            window.current_component_globle_remove_list.filter((item)=>{
              return item != this.$route.path
            });
          // 删除keep-alive的缓存
          if (this.$vnode.parent && this.$vnode.parent.componentInstance && this.$vnode.parent.componentInstance.cache)
          {
            if (this.$vnode.componentOptions)
            {
              var key = this.$vnode.key == null
                ? this.$vnode.componentOptions.Ctor.cid + (this.$vnode.componentOptions.tag ? `::${this.$vnode.componentOptions.tag}` : '')
                : this.$vnode.key;
              var cache = this.$vnode.parent.componentInstance.cache;
              var keys  = this.$vnode.parent.componentInstance.keys;
              if (cache[key])
              {
                if (keys.length) {
                  var index = keys.indexOf(key);
                  if (index > -1) {
                    keys.splice(index, 1);
                  }
                }
                delete cache[key];
              }
            }
          }
          //删除非当前页
          this.$destroy();
          console.log("step:2-2")
          //再一次进入
          // 怎么关闭当前multitale页面？？
          // --①先往回跳转
          if(window.current_component_globle_remove_step_2_2){
            //--②再跳转回来
            debugger
            console.log(window.current_component_globle_remove_step_2_2)
            let temp = window.current_component_globle_remove_step_2_2
            window.current_component_globle_remove_step_2_2 = this.$route.path

            this.$router.push({path:temp});
          }
        }
      }
      else{
        //--③再跳转回来
        if(window.current_component_globle_remove_step_2_2){
          let temp_ = window.current_component_globle_remove_step_2_2
          window.current_component_globle_remove_step_2_2 = null
          this.$router.push({path:temp_})
        }
      }

    }
  },
  beforeRouteUpdate:function(to, from, next){
    // 痛点②：关闭非当前页怎么删除它们在keep-alive的缓存？
    if (!window.current_component_globle_remove){
      let findName_ = window.current_component_globle_remove_list.find((item)=>{
        return item == to.path
      })
      // //被当前页面关闭过，现在要打开关闭的页面
      if(findName_){
        //清除keep-alive和组件，重新初始化
        console.log("step:2")
        //转：activated

        window.current_component_globle_remove_step_2_2 = from.fullPath
      }
      // //没有被当前页面关闭过，当前页面跳转
      else{
        //TODO
        console.log("step:3")
      }
      next();
    }
  },
  beforeRouteLeave:function(to, from, next){
    //痛点①：关闭当前页时候怎么删除当前页在keep-alive的缓存？
    if(window.current_component_globle_remove){
      let findName = window.current_component_globle_remove_list.find((item)=>{
        return item == from.path
      })
      //from就是关闭的页面
      if(findName){
        //删除关闭页面的keep-alive
        if (this.$vnode && this.$vnode.data.keepAlive)
        {
          if (this.$vnode.parent && this.$vnode.parent.componentInstance && this.$vnode.parent.componentInstance.cache)
          {
            if (this.$vnode.componentOptions)
            {
              var key = this.$vnode.key == null
                ? this.$vnode.componentOptions.Ctor.cid + (this.$vnode.componentOptions.tag ? `::${this.$vnode.componentOptions.tag}` : '')
                : this.$vnode.key;
              var cache = this.$vnode.parent.componentInstance.cache;
              var keys  = this.$vnode.parent.componentInstance.keys;
              if (cache[key])
              {
                if (keys.length) {
                  var index = keys.indexOf(key);
                  if (index > -1) {
                    keys.splice(index, 1);
                  }
                }
                delete cache[key];
              }
            }
          }
        }
        //删除关闭页面
        this.$destroy();
        //重置不是当前页面关闭
        window.current_component_globle_remove = false
        window.current_component_globle_remove_list =
          window.current_component_globle_remove_list.filter((item)=>{
            return item != from.path
          });
        console.log("step:1")
      }else{
        window.current_component_globle_remove = false
        console.log("step:1-no")
      }
    }
    next();
  }
});



new Vue({
  router,
  store,
  i18n,
  // init localstorage, vuex, Logo message
  created: bootstrap,
  render: h => h(App)
}).$mount('#app')
export default app
