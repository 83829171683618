import request from '@/utils/request'

export default {

  /**
   * 获取商户列表
   * @param parameter
   * @returns {AxiosPromise}
   */
  getTenantList () {
    return request({
      url: '/warehouse/warehouse/warehouse/listTenant',
      method: 'get',
      data: null,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    })
  },

  /**
   * 获取门店列表
   * @param parameter
   * @returns {AxiosPromise}
   */
  getWarehouseList (parameter) {
    return request({
      url: '/warehouse/warehouse/warehouse/listWarehouseByOrganize',
      method: 'get',
      data: parameter,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    })
  },
  /**
   * 获取门店列表
   * @param parameter
   * @returns {AxiosPromise}
   */
  getWarehouseListType (parameter) {
    return request({
      url: '/warehouse/warehouse/warehouse/listWarehouseByOrganizeByType?type='+parameter,
      method: 'get',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    })
  },

  /**
   * 根据商户ID获取门店列表
   * @param parameter
   * @returns {AxiosPromise}
   */
  getWarehouseListByTid (parameter) {
    return request({
      url: '/warehouse/warehouse/warehouse/listWarehouseByTid?tid='+parameter,
      method: 'get',
      data: null,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    })
  },
  //上传图片
  uploadImage (parameter) {
    return request({
      url: '/file/uploadList',
      method: 'post',
      data: parameter,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  //获取省列表
  getProvinceList(parameter) {
    return request({
      url: 'nlms/address/listProvince',
      method: 'get',
      data: parameter,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    })
  },
  //获取市列表
  getCityList(parameter) {
    return request({
      url: 'nlms/address/listAddress',
      method: 'post',
      data: parameter,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    })
  },
  //获取字典信息
  getDictionary(parameter) {
    return request({
      url: '/ums/dictionary/dictionary/getDictionaryListByDKey?dKey='+parameter,
      method: 'post',
      data: null,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    })
  },

  /**
   * 导出
   * @param fun
   * @param parameter
   * @returns {AxiosPromise}
   */
  exportExcel(fun,parameter) {
    return request({
      url: fun.uri+"?"+parameter,
      method: fun.httpMethod,
      data: null,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      responseType : 'blob', // 必须注明返回二进制流
    })
  },
  //加法
  floatAdd(a, b) {
    var c, d, e;
    if (undefined == a || null == a || "" == a || isNaN(a)) {
      a = 0;
    }
    if (undefined == b || null == b || "" == b || isNaN(b)) {
      b = 0;
    }
    try {
      c = a.toString().split(".")[1].length;
    } catch (f) {
      c = 0;
    }
    try {
      d = b.toString().split(".")[1].length;
    } catch (f) {
      d = 0;
    }
    e = Math.pow(10, Math.max(c, d));
    return parseFloat((this.floatMul(a, e) + this.floatMul(b, e)) / e);
  },
  //减法
  floatSub(arg1, arg2) {
    var r1, r2, m, n;
    try {
      r1 = arg1.toString().split(".")[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = arg2.toString().split(".")[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    //动态控制精度长度
    n = (r1 >= r2) ? r1 : r2;
    return parseFloat(((arg1 * m - arg2 * m) / m).toFixed(n));
  },
  //乘法
  floatMul(a, b) {
    var c = 0,
      d = a.toString(),
      e = b.toString();
    try {
      c += d.split(".")[1].length;
    } catch (f) {}
    try {
      c += e.split(".")[1].length;
    } catch (f) {}
    return Number(d.replace(".", "")) * Number(e.replace(".", "")) / Math.pow(10, c);
  },
  //除法
  floatDiv(arg1, arg2) {
    //保留的小数点后的位数
    let digit = 2;
    var t1 = 0,
      t2 = 0,
      r1, r2;
    try {
      t1 = arg1.toString().split(".")[1].length
    } catch (e) {}
    try {
      t2 = arg2.toString().split(".")[1].length
    } catch (e) {}
    r1 = Number(arg1.toString().replace(".", ""))
    r2 = Number(arg2.toString().replace(".", ""))
    //获取小数点后的计算值
    var result = ((r1 / r2) * Math.pow(10, t2 - t1)).toString()
    var result2 = result.split(".")[1];
    result2 = result2.substring(0, digit > result2.length ? result2.length : digit);
    return Number(result.split(".")[0] + "." + result2);
  },
  //验证手机号码
  isValidPhoneNumber(phoneNumber){
    var pattern =/^((\+|00)86)?1[3-9]\d{9}$|^852\d{8}$|^853\d{8}$|^(886|0)9\d{8}$/;
    return pattern.test(phoneNumber);
  },
  //会员注册
  getMemberRegisterList(){
    let list = window.localStorage.getItem('memberRegisterList');
    if(typeof(list)=="undefined" || null==list){
      return [];
    }
    list = JSON.parse(list);
    let first = [];
    for(let i=list.length-1;i>=0;i--){
      first.push({"label":list[i].label,"value":list[i].label});
    }
    return first;
  },
  //保存会员注册
  setMemberRegister(phone){
    let list = window.localStorage.getItem('memberRegisterList');
    if(typeof(list)=="undefined" || null==list){
      list=[];
    }else{
      list = JSON.parse(list);
    }
    for(let item of list){
      if(item.value ==phone){
        return;
      }
    }
    if(list.length==5){
      list.splice(list[0],1);
    }
    list.push({"label":phone,"value":phone});
    window.localStorage.setItem('memberRegisterList',JSON.stringify(list));
  },
}