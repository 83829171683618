import axios from 'axios'
import store from '@/store'
import storage from 'store'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'
import {getCookie,addCookie} from "@/utils/cookie";
import {getRequestCommon, postRequest} from "@/api/common";

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 90000// 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {

  if (error.response) {
    const data = error.response.data
    // 从 localstorage 获取 token
    const token = storage.get(ACCESS_TOKEN)
    if (error.response.status === 403) {
      notification.error({
        message: 'Forbidden',
        description: data.message
      })
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      notification.error({
        message: 'Unauthorized',
        description: 'Authorization verification failed'
      })
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {

  let requestUrl = config.url;

  console.log(requestUrl)

  //过期时间 cookie 配置
  let expire_time = 'expire_time';
  //获取当前时间
  let now = Date.now();
  let res =  getCookie(expire_time);
  if( typeof(res)!="undefined" && typeof(requestUrl)!="undefined" && requestUrl.indexOf('refreshUserToken')==-1){
    res = parseInt(res) - now;
    if(res >0 && res <= 10 * 60 * 1000 && requestUrl.indexOf('refreshUserToken')==-1){
      let func = {"uri": "/ums/auth/refreshUserToken", "httpMethod": "get"};
      return getRequestCommon({},func).then((result) => {
        if(result.code=='200'){
          let token = result.data.token;
          addCookie(ACCESS_TOKEN, token,18000);
          //设置过期时间
          now = Date.now();
          let reftime = result.data.refreshTime * 1000;

          let newTime = reftime + now;
          addCookie(expire_time,  newTime ,18000);
          storage.set(ACCESS_TOKEN, token, new Date().getTime() + 30 * 60 * 1000)
          store.commit('SET_TOKEN', token)

          config.headers[ACCESS_TOKEN] = token

          return config;
        }else{
          this.$message.error("token刷新异常");
          return config;
        }
      })
    }
  }
  let token = storage.get(ACCESS_TOKEN)
  let cookie = getCookie(ACCESS_TOKEN)
  if(!token && cookie ){
    token = cookie;
    store.commit('SET_TOKEN', token)
  }
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers[ACCESS_TOKEN] = token
  }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  return response.data
}, errorHandler)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
