import request from '@/utils/request'
const commomApi = {
  uploadUrl: '/file/uploadList',
}

export function getUpdateImage (parameter) {
  return request({
    url: commomApi.uploadUrl,
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function getRequestCommon(parameter,fun) {
  return request({
    url: fun.uri,
    method: fun.httpMethod,
    params: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function postRequest(parameter,fun) {
  return request({
    url: fun.uri,
    method: fun.httpMethod,
    data: parameter,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 详情页面
 * @param fun
 * @param id
 * @returns {AxiosPromise}
 */
export function getRequestId (fun,id) {
  const bigInt = BigInt(id);
  return request({
    url: fun.uri,
    method: fun.httpMethod,
    params:{
      id:bigInt
    },
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}


export function getRequestRtId (fun,id) {
  const bigInt = BigInt(id);
  return request({
    url: fun.uri,
    method: fun.httpMethod,
    params:{
      rtId:bigInt
    },
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}


export function getRequest (fun) {
  return request({
    url: fun.uri,
    method: fun.httpMethod,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

// 查询用户手机号
export function getUserPost(parameter,fun) {
  return request({
    url: fun.uri,
    method: fun.httpMethod,
    params:{
      phone:parameter
    },
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

// 根据仓库id
export function getWidPost(parameter,fun) {
  return request({
    url: fun.uri,
    method: fun.httpMethod,
    params:{
      wid:parameter
    },
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
// 活动页面
export function getAidPost(parameter,fun) {
  return request({
    url: fun.uri,
    method: fun.httpMethod,
    params:{
      aid:parameter
    },
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
//获取抵扣券活动
export function getAidPostByWid(parameterOne,parameterTwo,fun) {
  return request({
    url: fun.uri,
    method: fun.httpMethod,
    params:{
      wid:parameterOne,
      actType: parameterTwo
    },
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}


//获取字典数据  dKey
export function getDictionaryByDKey(parameterOne,fun) {
  return request({
    url: fun.uri,
    method: fun.httpMethod,
    params:{
      dKey:parameterOne,
    },
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 获取商户列表
 * @param parameter
 * @returns {AxiosPromise}
 */
export function getTenantList () {
  return request({
    url: '/user/listTenant',
    method: 'post',
    data: null,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}





