function addCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
//获取指定名称的cookie的值
function getCookie(objName){
  var arrStr = document.cookie.split("; ");
  for (var i = 0; i < arrStr.length; i++) {
    var temp = arrStr[i].split("=");
    if (temp[0] == objName)
      return unescape(temp[1]);
  }
}

//为了删除指定名称的cookie，可以将其过期时间设定为一个过去的时间
function delCookie(name){
  document.cookie = `${encodeURIComponent(name)}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

/**
 * 获取用户ID
 */
function getUserId(){
  return getCookie('rid');
}

export{
  addCookie,getCookie,delCookie,getUserId
}