import storage from 'store'
import expirePlugin from 'store/plugins/expire'
import { login, getInfo, logout } from '@/api/login'
import { ACCESS_TOKEN,TID,NEW_ACCESS_TOKEN } from '@/store/mutation-types'
import {constantRouterMap} from "@/config/router.config";
import {getCookie,delCookie, addCookie} from '@/utils/cookie' //注意路径
// import { welcome } from '@/utils/util'

storage.addPlugin(expirePlugin)
const user = {
  state: {
    token: '',
    name: '',
    welcome: '',
    avatar: '',
    roles: [],
    info: {},
    tid: '',
    functions: new Map(),
    menuList: [],
    productList: [],
    front_project: new Map(),
    routing_matching: new Map(),
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, { name, welcome }) => {
      state.name = name
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_FUNCTIONS: (state,functions) => {
      state.functions = functions
    },
    SET_TID: (state,tid) => {
      state.tid = tid
    },
    SET_MENU_LIST: (state, menuList) => {
      state.addRouters = menuList
    },
    SET_PRODUCT_LIST: (state, productList) => {
      state.productList = productList
    },
    SET_FRONT_PROJECT: (state,front_project) => {
      state.front_project = front_project;
    },
    SET_ROUTING_MATCHING: (state,routing_matching) => {
      state.routing_matching = routing_matching;
    }

  },

  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          if (response.success) {
            //设置过期时间
            let now = Date.now();
            let reftime = response.data.refreshTime * 1000;
            let res = reftime + now;
            addCookie("expire_time",  res );

            addCookie(ACCESS_TOKEN,response.data.token,new Date().getTime() + 30 * 60 * 1000);
            addCookie(NEW_ACCESS_TOKEN,response.data.newToken,18000);
            storage.set(ACCESS_TOKEN, response.data.token, new Date().getTime() + 30 * 60 * 1000)
            commit('SET_TOKEN', response.data.token)
            if(response.data.tenantVo){
              storage.set(TID, response.data.tenantVo.id, new Date().getTime() + 30 * 60 * 1000)
              commit('SET_TID', response.data.tenantVo.id)
            }
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        // 请求后端获取用户信息 /api/user/info
        getInfo().then(response => {
          const data = response.data[0]
          if (data != null) {
            commit('SET_ROLES', data)
            commit('SET_NAME',{name:data.name})
            //addCookie("roles",data,new Date().getTime() + 30 * 60 * 1000);
            //addCookie("name",data.name,new Date().getTime() + 30 * 60 * 1000);
            resolve(data)
          } else {
            reject(new Error('getInfo: not permissions !'))
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    Logout ({ commit, state }) {

      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      delCookie(ACCESS_TOKEN)
      storage.remove(ACCESS_TOKEN)
/*      return new Promise((resolve) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          storage.remove(ACCESS_TOKEN)
          resolve()
        }).catch((err) => {
          console.log('logout fail:', err)
          // resolve()
        }).finally(() => {
        })
      })*/


    },

    // 获取功能Map
    setFunctions({ commit }, functions){
      if(functions){
        let functionMap = new Map();
        functions.forEach((fun) => {
          const value = {httpMethod:fun.httpMethod,uri:fun.uri}
          functionMap.set(fun.code,value)
        })
        commit('SET_FUNCTIONS', functionMap)
      }
    },

    // 获取菜单列表
    setMenuList({commit}, menuList) {
      commit('SET_MENU_LIST', menuList)
    },

    // 保存产品量
    setProductList({commit}, productList){
      commit('SET_PRODUCT_LIST', productList)
    },

    // 前端跳转路径
    setFrontProject({commit},front_project) {
      let front_project_map = new Map();
      front_project.forEach( (fp) => {
        front_project_map.set(fp.dvalue,fp.extend1)
      })
      commit('SET_FRONT_PROJECT',front_project_map)
    },

    // 前端模块路由
    setRoutingMatching({commit}, routingMatching) {

      if (routingMatching) {
        let routing_matching_map = new Map();
        routingMatching.forEach( (routMa) => {
          let routes = {};
          routes.code = routMa.code;
          routes.path =routing_matching_map.set(routMa.path, routes);
        })
        commit('SET_ROUTING_MATCHING',routing_matching_map)
      }
    },



  }
}

export default user
